






















import { Vue, Component, Prop, VModel } from 'vue-property-decorator'
import { Contact, Meeting } from '@tada-team/tdproto-ts'

@Component({
  components: {
    MeetingHeader: () => import('./Header/index.vue'),
    MeetingHeaderSkeleton: () => import('./Header/Skeleton.vue'),
    MeetingMeta: () => import('./Meta/index.vue'),
    MeetingDescription: () => import('./Description/index.vue'),
  },
})
export default class MeetingArea extends Vue {
  @VModel({
    required: true,
    type: Boolean,
  }) importantViewerActive!: boolean

  @Prop({
    required: true,
    type: Contact,
  }) owner!: Contact

  @Prop({
    required: true,
    type: Meeting,
  }) meeting!: Meeting

  @Prop({
    type: Boolean,
  }) isSmallMode!: boolean
}
